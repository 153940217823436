<template>
  <div class="c-language" @click="switchExpandStatus">
    <template v-if="iconColor === 'white'">
      <img src="@/assets/icon/header_language.svg" />
      <img class="icon-more" src="@/assets/icon/header_language_more.svg" />
    </template>
    <template v-else>
      <img src="@/assets/icon/header_language_black.svg" />
      <img class="icon-more" src="@/assets/icon/header_language_more_black.svg" />
    </template>
    <template v-if="expandStatus">
      <div class="language-dropdown" :class="[`language-dropdown--${iconColor}`]" v-click-away="switchExpandStatus">
        <language-list />
      </div>
    </template>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import LanguageList from '@/components/Layout/Language/LanguageList.vue';

export default defineComponent({
  name: 'Language',
  components: {
    LanguageList,
  },
  props: {
    iconColor: {
      type: String,
      default: 'white',
    },
  },
  setup() {
    const expandStatus = ref(false);
    const switchExpandStatus = () => {
      expandStatus.value = !expandStatus.value;
    };

    // const changeLang = (lng) => {
    //   lang.value = lng;
    //   app.ctx.$root.$i18n.locale = lng;
    // };

    return {

      expandStatus,
      switchExpandStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-language {
  @include flex();
  position: relative;

  // TOFIX 暫時關閉
  // display: none;
}

.icon-more {
  margin-left: 8px;
}

.language-dropdown {
  z-index: $zi-language-dropdown;
  background-color: $c-white;
  width: 160px;

  &--white {
    @include position(tr, calc(100% + 30px), -20px);
  }

  &--black {
    @include position(tr, calc(100% + 29px), -20px);
  }
}
</style>
