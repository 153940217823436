<template>
  <ul class="ly-mobile-header-function-list funcition-list">
    <li class="funcition-list__item funcition-list__item--search" v-if="search">
      <div class="red-search" @click="openSearchPop" >
        <img class="red-search__icon" src="@/assets/searchResults/icon_search_white.svg" />
      </div>
    </li>
    <li class="funcition-list__item">
      <div class="hbg-control" @click="handleHbg">
        <div class="hbg hbg--open" :class="[`hbg--open--${hbgType}`, { 'hbg--active': !showMobileMenuContent }]"></div>
        <div class="hbg hbg--close1" :class="[`hbg--close1--${hbgType}`, { 'hbg--active': showMobileMenuContent }]"></div>
        <div class="hbg hbg--close2" :class="[`hbg--close2--${hbgType}`, { 'hbg--active': showMobileMenuContent }]"></div>
      </div>
    </li>
  </ul>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MobileHeaderFunctionList',
  props: {
    search: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const siteStore = useSiteStore();
    const showMobileMenuContent = computed(() => siteStore.showMobileMenuContent);

    const openSearchPop = () => {
      siteStore.toggleShowSearchPop(true);
    };

    const handleHbg = () => {
      siteStore.toggleShowMobileMenuContent(!showMobileMenuContent.value);
    };

    const hbgType = computed(() => {
      if (props.type === 'home') {
        return 'home';
      }

      if (route.meta.layout?.headerStyle === 'black') {
        return 'menu-wmw';
      }
      return 'menu-bmb';
    });

    return {
      isLogin: computed(() => siteStore.isLogin),
      hbgType,
      openSearchPop,
      showMobileMenuContent,
      handleHbg,
    };
  },
});
</script>
<style lang="scss" scoped>
.funcition-list {
  @include flex();

  &__item {
    cursor: pointer;

    &--search {
    }

    &--user {
      margin-left: 23px;
      @include flex();
    }

    &--language {
      margin-left: 43px;
      @include flex();
    }

    &__sign-in {
      margin-left: 12px;

      &--white {
        @include font-style($c-white, 16, 400, 0.8px);
      }

      &--black {
        @include font-style($c-black, 16, 400, 0.8px);
      }
    }
  }
}

.hbg-control {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  margin-left: 20px;
}

.hbg {
  @include position(center);
  transition: 0.6s;
  opacity: 0;

  &--open {
    width: 100%;
    height: 4px;

    border-radius: 2px;

    &--home {
      background-color: $c-white;
      box-shadow: 0 10px 0 $c-white, 0 -10px 0 $c-white;
    }

    &--menu-bmb {
      background-color: $c-main;
      box-shadow: 0 10px 0 $c-black, 0 -10px 0 $c-black;
    }

    &--menu-wmw {
      background-color: $c-main;
      box-shadow: 0 10px 0 $c-white, 0 -10px 0 $c-white;
    }
  }

  &--close1 {
    width: 100%;
    height: 4px;

    transform: translate(-50%, -50%) rotate(45deg);
    border-radius: 2px;

    &--home {
      background-color: $c-white;
    }

    &--menu-bmb {
      background-color: $c-black;
    }

    &--menu-wmw {
      background-color: $c-white;
    }
  }

  &--close2 {
    width: 100%;
    height: 4px;

    transform: translate(-50%, -50%) rotate(-45deg);
    border-radius: 2px;

    &--home {
      background-color: $c-white;
    }

    &--menu-bmb {
      background-color: $c-black;
    }

    &--menu-wmw {
      background-color: $c-white;
    }
  }

  &--active {
    opacity: 1;
  }
}

.red-search {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  @include flex(center);
  background-color: $c-main;

  &__icon {
    width: 16px;
  }
}
</style>
