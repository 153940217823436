<template>
  <div class="menu-search-box" v-show="showIcon">
    <img class="menu-search-box__icon" src="@/assets/icon/header_search_2.svg" @click="handleClick" />
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MenuSearch',
  emits: ['update:showSearchPop'],
  setup() {
    const route = useRoute();
    const siteStore = useSiteStore();
    const handleClick = () => {
      siteStore.toggleShowSearchPop(true);
    };

    const showIcon = computed(() => {
      console.log('route.name', route.name);
      if (route.name === 'search') {
        return false;
      }

      return true;
    });

    return {
      handleClick,
      showIcon,
    };
  },
});
</script>
<style lang="scss" scoped>
.menu-search-box {
  display: none;
}

@media screen and (min-width: $tablet) {
  .menu-search-box {
    // @include position(tr, calc(100% + 1px), 0);
    display: inline-block;
    @include position(tr, 80px, 0px);
    width: 60px;
    height: 60px;
    border-bottom-left-radius: 10px;
    background-color: $c-main;
    cursor: pointer;
    box-shadow: 0px 3px 10px rgba(100, 4, 4, 0.25);

    &__icon {
      @include position(center);
    }
  }
}
</style>
