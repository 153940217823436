const menuList = [
  {
    key: 'explore',
    label: '探索',
    hoverLabel: '探索',
    routeName: '',
  },
  {
    key: 'licensing',
    label: '授權聲明',
    hoverLabel: '授權聲明',
    routeName: 'licensing',
  },
  {
    key: 'contact',
    label: '聯絡我們',
    hoverLabel: '聯絡我們',
    routeName: 'front-contact',
  },

];

export default menuList;
