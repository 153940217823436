<template>
  <div class="f-menu-explore">

      <div class="f-menu-explore__content">
        <div class="explore-list">
          <div class="explore-list__left">
            <ul class="explore-list__left__content hot-album">
              <li
                class="hot-album__item"
                v-for="item of menuExploreLeft"
                :key="item.label"
                @click="toPage(item.routeType)"
              >
                <img class="hot-album__item__img" :src="menuExploreImg[item.imgIndex]" />
                <div class="hot-album__item__text">
                  <p class="hot-album__item__text__label">{{ item.label }}</p>
                  <p class="hot-album__item__text__desc">{{ item.desc }}</p>
                  <p class="hot-album__item__text__learn-more">了解更多</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="explore-list__right">
            <ul class="explore-list__right__content hot-album">
              <li
                class="hot-album__item"
                v-for="item of menuExploreRight"
                :key="item.label"
                @click="toPage(item.routeType)"
              >
                <img class="hot-album__item__img" :src="menuExploreImg[item.imgIndex]" />
                <div class="hot-album__item__text">
                  <p class="hot-album__item__text__label">{{ item.label }}</p>
                  <p class="hot-album__item__text__desc">{{ item.desc }}</p>
                  <p class="hot-album__item__text__learn-more">了解更多</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { menuExplore, menuExploreImg } from '@/config/menuExplore';

export default defineComponent({
  name: 'MenuExplore',
  emits: ['closeInnerContent'],
  setup(props, { emit }) {
    const router = useRouter();
    const toPage = (type) => {
      console.log('type', type);
      router.push({
        name: 'explore-type',
        params: {
          type,
        },
      });

      emit('closeInnerContent');
    };

    const menuExploreLeft = menuExplore.filter((item, idx) => idx < 3);
    const menuExploreRight = menuExplore.filter((item, idx) => idx >= 3);

    return {
      menuExploreLeft,
      menuExploreRight,
      menuExploreImg,
      toPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.f-menu-explore {
  width: 100%;
  background-color: $c-white;

  &__content {
    @include max-width(1366);
    @include padding(55px 0);
    width: 100%;
  }
}

.explore-list {
  &__left {
    &__content {
    }
  }

  &__right {
    margin-top: 37px;

    &__content {
      @include flex(flex-end);
    }
  }
}

.hot-album {
  display: flex;
  width: 100%;

  &__item {
    position: relative;
    overflow: hidden;
    width: 350px;
    cursor: pointer;

    & + & {
      margin-left: 5px;
    }

    &__img {
      width: 100%;
      vertical-align: bottom;
    }

    &__text {
      display: inline-block;
      width: 100%;
      height: 100%;
      @include position(tl, 41%, 0);
      @include padding(30px);
      @include flex(center, flex-start, column);
      transition: 0.4s;

      &__label {
        @include font-style($c-white, 18, normal, 0px, 24px);
        margin-bottom: 8px;
      }

      &__desc {
        @include font-style($c-white, 16, normal, 0px, 24px);
        display: none;
      }

      &__learn-more {
        @include font-style($c-main, 12, normal, 0.8px, 16px);
        @include padding(0 0 0 15px);
        position: relative;
        margin-top: 16px;
        display: none;

        &::before {
          content: '';
          @include circle(6px);
          position: absolute;
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
          display: inline-block;
          background-color: $c-main;
        }
      }
    }
  }
}

.hot-album__item {
  &:hover {
    .hot-album__item__img {
      filter:brightness(40%);
      transition: 0.4s;
    }
    .hot-album__item__text {
      top: 0%;

      &__desc {
        display: inline-block;
      }

      &__learn-more {
        display: inline-block;
      }
    }
  }
}
</style>
